<template>
<div v-if="visible" class="modal">
  <div
    class="close"
    @click="closeModal"
    ><v-icon icon="mdi-close" /></div>
  <div class="modal-content">
    <slot />
  </div>
</div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from 'vue'

const props = defineProps({ modelValue: Boolean })

const emit = defineEmits(['update:modelValue'])
const visible = ref(props.modelValue)

watch(() => props.modelValue, newVal => visible.value = newVal)

const closeModal = () => {
  visible.value = false
  emit('update:modelValue', false)
}
</script>

<style>
.modal {
  pointer-events: auto;
  display: block;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  position: relative;
  backdrop-filter: blur(10px);
  z-index: 20;
  padding: 2vw;
}

.close {
  position: fixed;
  top: .4rem;
  right: .8rem;
  z-index: 200;
  display: block;
  color: white;
  text-shadow: 1px 1px 1px black;
  background: transparent;
  font-size: 2rem;
}

.close:hover,
.close:focus {
  cursor: pointer;
}

p, h2, h3, ul, ol {
  line-height: 1.8;
  margin-bottom: 1em;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.8rem;
}

p, ul, ol {
  font-size: 1.4rem;
}
</style>
