<template>
<canvas />
</template>

<script>
import * as BABYLON from '@babylonjs/core'
import '@babylonjs/loaders'
import '@babylonjs/core/Debug/debugLayer'
import '@babylonjs/inspector'
const log = console.log; log()

export default {
  data() {
    return {
      engine: null,
      scene: null,
      camera: null,
      selected: null,
    }
  },
  methods: {
    async loadModel(meshName, position, scene) {
      const result = await BABYLON.SceneLoader.ImportMeshAsync(
        meshName, '/models/', 'Room.glb', scene
      )
      result.animationGroups.forEach(group => group.play(true))
      result.meshes.forEach(mesh => {
        mesh.name = mesh.name.split(/[._]/)[0]
        switch (mesh.name) {
          case 'Owl':
            break
          default:
            break
        }
        mesh.actionManager = new BABYLON.ActionManager(scene)
        mesh.actionManager.registerAction(
          new BABYLON.ExecuteCodeAction(
            BABYLON.ActionManager.OnPickTrigger, () => {
              this.$emit('selectedMesh', mesh.name)
            }))
      })
      const model = result.meshes[0]
      model.position = new BABYLON.Vector3(...position)
      return model
    },
  },
  async mounted() {
    const canvas = this.$el
    this.engine = new BABYLON.Engine(canvas, true)
    this.scene = new BABYLON.Scene(this.engine)
    this.scene.clearColor = new BABYLON.Color4(1, 1, 1, 1)
    this.camera = new BABYLON.ArcRotateCamera(
      'camera', Math.PI / 2, Math.PI / 2, 0,
      new BABYLON.Vector3(0, 80, 0), this.scene
    )
    this.camera.lowerAlphaLimit = 0
    this.camera.upperAlphaLimit = Math.PI
    // this.camera.lowerBetaLimit = 0
    this.camera.upperBetaLimit = Math.PI / 2
    this.camera.lowerRadiusLimit = 500
    this.camera.upperRadiusLimit = 2000
    this.camera.attachControl(canvas, true)
    const hemi = new BABYLON.HemisphericLight(
      'light', new BABYLON.Vector3(100, 100, 0), this.scene)
    hemi.intensity = 1.1
    await this.loadModel('', [0, 0, 0], this.scene)
    new BABYLON.LensRenderingPipeline('lensEffects', {
      chromatic_aberration: 8,
      grain_amount: 4,
      distortion: 1,
      // dof_threshold: 100,
      // edge_blur: 0.2,
      // dof_pentagon: true,
      // dof_focus_distance: 38,
      // dof_aperture: 1.2,
      // dof_gain: 0.2,
      // dof_darken: 0.25
    }, this.scene, 0.0)
    
    this.scene.postProcessRenderPipelineManager
      .attachCamerasToRenderPipeline('lensEffects', this.camera)
    this.engine.runRenderLoop(() => this.scene.render())
    window.addEventListener('resize', () => this.engine.resize())
    this.$emit('isLoaded', true)
    // this.scene.debugLayer.show()
  }
}
</script>

<style>
canvas {
  width: 100%;
  height: 100%;
  outline: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
</style>
