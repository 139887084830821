<script setup>
const log = console.log; log()
import markdownit from 'markdown-it'
import text from '@/assets/restoration.md'
const md = markdownit()
const body = md.render(text)
</script>

<template>
  <h1>Restoration</h1>
  <div class="restoration-description" v-html="body">
  </div>
</template>

<style>
.restoration-description ul {
  padding-left: 4em;
}
</style>
