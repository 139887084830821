import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './components/HomePage.vue'

const router = createRouter({
  history: createWebHistory('/'),
  routes: [
    { path: '/', component: HomePage },
    { path: '/about', component: HomePage },
    { path: '/gallery', component: HomePage },
    { path: '/restoration', component: HomePage },
  ],
})

export default router
