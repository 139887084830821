<script setup>
import { ref } from 'vue'
const log = console.log; log()

const images = ref([
  'IMG_2581.png',
  'IMG_2584.png',
  'IMG_2650.png',
  'IMG_2678.png',
  'IMG_2723.png',
  'IMG_2729.png',
  'IMG_2749.png',
  'IMG_2844.png',
  'IMG_2897.png',
  'IMG_2900.png',
  'IMG_3028.png',
])
</script>

<template>
  <h1>Gallery</h1>
  <div
    ref="masonry"
    class="masonry"
    v-masonry>
    <div
      class="item"
      v-masonry-tile
      v-for="(image, index) in images"
      :key="index">
      <img
        :src="`/images/${image}`"
        />
    </div>
  </div>
</template>

<style>
.gallery {
  padding: 20px;
}

.masonry {
  display: flex;
  margin-left: -10px;
  width: auto;
}

.item {
  margin-left: 10px;
  margin-bottom: 10px;
  width: calc(50% - 10px);
}

.item img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}
</style>
