import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { VueMasonryPlugin } from 'vue-masonry'
import mitt from 'mitt'
const emitter = mitt()
const app = createApp(App)

import axios from 'axios'
import VueAxios from 'vue-axios'

loadFonts()

app.config.globalProperties.emitter = emitter
app.use(vuetify)
  .use(VueAxios, axios)
  .use(router)
  .use(VueMasonryPlugin)
  .mount('#app')
