<script setup>
const log = console.log; log()
import markdownit from 'markdown-it'
import text from '@/assets/about.md'
const md = markdownit()
const body = md.render(text.replace(/<!-- start -->[\s\S]*?<!-- end -->/g, ''))
</script>

<template>
  <div class="about-description" v-html="body">
  </div>
</template>

<style>
.about-description {
  padding-bottom: 24vh;
}
.about-description h1,
.about-description h2 {
  text-align: center;
}
.about-description h1 {
  font-style: normal;
  font-size: 1em;
  padding: 24vh 0 0;
}
.about-description h2 {
  font-size: .4em;
  margin: 0 0 24vh;
}
.about-description p {
  margin-bottom: 4em;
  line-height: 2;
}
</style>
